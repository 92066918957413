@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
  @apply bg-gray-100;
}

.wrapper {
  @apply py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-12;
}

.section {
  @apply w-screen min-h-screen relative flex;
}

@import "/home/mrek/Projekty/palacpaprotno.pl/src/components/heroBanner/css/heroBanner.scss";