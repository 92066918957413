@keyframes animation01 {
    0% { opacity: 1; }
    10% { opacity: 1; }
    20% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation02 {
    0% { opacity: 1; }
    20% { opacity: 1; }
    30% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation03 {
    0% { opacity: 1; }
    30% { opacity: 1; }
    40% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation04 {
    0% { opacity: 1; }
    40% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation05 {
    0% { opacity: 1; }
    50% { opacity: 1; }
    60% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation06 {
    0% { opacity: 1; }
    60% { opacity: 1; }
    70% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation07 {
    0% { opacity: 1; }
    70% { opacity: 1; }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation08 {
    0% { opacity: 1; }
    80% { opacity: 1; }
    90% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes animation09 {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

#bgSlideshow img:nth-child(10) {
    animation: animation01 60s ease infinite;
}
#bgSlideshow img:nth-child(9) {
    animation: animation02 60s ease infinite;
}
#bgSlideshow img:nth-child(8) {
    animation: animation03 60s ease infinite;
}
#bgSlideshow img:nth-child(7) {
    animation: animation04 60s ease infinite;
}
#bgSlideshow img:nth-child(6) {
    animation: animation05 60s ease infinite;
}
#bgSlideshow img:nth-child(5) {
    animation: animation06 60s ease infinite;
}
#bgSlideshow img:nth-child(4) {
    animation: animation07 60s ease infinite;
}
#bgSlideshow img:nth-child(3) {
    animation: animation08 60s ease infinite;
}
#bgSlideshow img:nth-child(2) {
    animation: animation09 60s ease infinite;
}

